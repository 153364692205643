import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/storage';
import { getPlatforms } from '@ionic/vue';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload

const uploadPaymentProof = async (file: any, customerName: string) => {
  // compress the image
  file = await new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = file.name.split('.').pop();
  const fileName = `${moment().format('YYYYMMDDHHmmss')}-${customerName.substring(0, 10)}.${extension}`;
  const snapshot = await firebase.storage().ref(`orderPaymentProof-${fileName}`).put(file);
  return await snapshot.ref.getDownloadURL();
}

export default {
  // Stripe
  async createPaymentIntent(currency: any, amount: any, paymentMethodType: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-createPaymentIntent')({ currency, amount, paymentMethodType })).data;
  },
  async retrievePaymentMethods(stripeCustomerId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-retrievePaymentMethods')({ stripeCustomerId, })).data;
  },
  async detachPaymentMethod(paymentMethodId: any, immediateDetach = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-detachPaymentMethod')({ paymentMethodId, immediateDetach })).data;
  },
  async verifyPaymentMethod(user, paymentMethodId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-verifyPaymentMethod')({ user, paymentMethodId })).data;
  },

  // Discount
  async getDiscount(discountCode: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getDiscount')({ discountCode })).data;
  },

  // Checkout
  async createNewOrder(order: any, cartItems: any, paymentProofFile: any = null) {
    if (paymentProofFile) {
      // mainly for bank transfer orders
      order.paymentProof = await uploadPaymentProof(paymentProofFile, order.customerName);
    }
    order.sourceName = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('order-createNewOrder')({ order, cartItems });
    return res.data;
  }
}