
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { close, cardOutline, trashOutline, } from 'ionicons/icons';

// components
import { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
         IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
         IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
         IonButtons, IonButton, IonIcon, IonNote,
         onIonViewDidEnter, } from '@ionic/vue';
import LoadingSkeleton from '@/components/LoadingSkeleton.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

// API services
import OrderService from '@/services/OrderService';

export default {
  name: 'PaymentMethodListPage',
  components: { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
                IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonButtons, IonButton, IonIcon, IonNote,
                LoadingSkeleton },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, presentPrompt, } = utils();
    const store = useStore();

    const loading = ref(true);
    const stripePaymentMethods = ref([]);
    
    onIonViewDidEnter(() => {
      const { stripeCustomerId } = store.state.user;
      if (stripeCustomerId) {
        OrderService.retrievePaymentMethods(stripeCustomerId).then(res => {
          //console.log(res);
          stripePaymentMethods.value = res;
          loading.value = false;
        })
      } else {
        loading.value = false;
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, cardOutline, trashOutline,

      // variables
      loading, stripePaymentMethods,

      // methods
      t, getLocalizedStr,
      deletePaymentMethod: async (idx: any, paymentMethod: any) => {
        presentPrompt("", t('confirmDelete'), async () => {
          OrderService.detachPaymentMethod(paymentMethod.id, true);
          stripePaymentMethods.value.splice(idx, 1);
        });
      }
    }
  }
}
